<template>
  <v-app id="register">
    <header-register :step="1" />
    <v-container fluid>
      <div class="main-container">
        <div
          class="pb-6 mt-sm-n12"
          style="width: 80vh"
        >
          <v-row>
            <v-col
              class="txt-sub-6"
              cols="12"
            >
              Asal Keanggotaan
            </v-col>
          </v-row>

          <v-row>
            <v-col class="txt-sub-1">
              Silahkan lengkapi beberapa data diri di bawah
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Asal Provinsi <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-custom-select
                id="bpd"
                v-model="selectedPropinsi"
                :options="propinsi"
                :clearable="false"
                label="province_name"
                @search="getPropinsi"
                @input="getKabupatenKota"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <span class="mr-10">
                    Ketik minimal 3 huruf
                  </span>
                </template>
              </v-custom-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Asal Kab/Kota <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-custom-select
                v-model="selectedKabupatenKota"
                :options="kabupatenKota"
                :clearable="false"
                label="city_name"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <span class="mr-10">
                    Ketik minimal 3 huruf
                  </span>
                </template>
              </v-custom-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Jenis Keanggotaan <span class="symbol-required">*</span>
            </v-col>

            <v-col
              cols="12"
              sm="8"
              class="d-flex mt-n6"
              style="flex-direction: column"
            >
              <v-radio-group v-model="jenisKeanggotaan">
                <v-radio
                  value="1"
                  color="#38BA98"
                >
                  <template v-slot:label>
                    <span class="txt-sub-1">
                      Profesional
                      <span class="txt-sub-3"> (Untuk para pengusaha muda) </span>
                    </span>
                  </template>
                </v-radio>

                <v-radio
                  value="2"
                  color="#38BA98"
                >
                  <template v-slot:label>
                    <span class="txt-sub-1">
                      Perguruan Tinggi
                      <span class="txt-sub-3">
                        (Untuk para pengusaha muda tingkat Universitas)
                      </span>
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Tahu HIPMI dari?
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-textarea
                v-model="sumberInfo"
                color="#36AC87"
                rows="4"
                no-resize
                outlined
                placeholder="Penjelasan"
              />
            </v-col>
          </v-row>

          <v-row class="mt-n6">
            <v-col
              class="txt-sub-1"
              cols="12"
              sm="4"
            >
              Siapa yang mereferensikan?
            </v-col>

            <v-col
              cols="12"
              sm="8"
            >
              <v-text-field
                v-model="referensi"
                name="referensi"
                type="text"
                outlined
                autocomplete="off"
                dense
                color="#36AC87"
                placeholder="Referral"
              />
            </v-col>
          </v-row>

          <!-- Alert -->
          <v-row class="mb-n8">
            <v-col>
              <v-alert
                v-model="showAlert"
                type="error"
                dismissible
              >
                {{ alertText }}
              </v-alert>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="text-capitalize"
                color="#36AC87"
                block
                x-large
                :disabled="disableButton"
                small
                @click="submit"
              >
                <span class="txt-btn-login"> Lanjutkan Pendaftaran </span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import RegistrasiHeader from '../registrasi/RegistrasiHeader.vue'
  // import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  // axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      'header-register': RegistrasiHeader,
    },

    data: () => ({
      // Form Data:
      selectedPropinsi: [
        {
          province_name: 'Pilih Provinsi',
        },
      ],
      propinsi: [],

      selectedKabupatenKota: [
        {
          city_name: 'Pilih Kabupaten/ Kota',
        },
      ],
      kabupatenKota: [],

      sumberInfo: '',

      referensi: '',

      jenisKeanggotaan: '1',

      disableButton: false,

      // Alert:
      showAlert: false,
      alertText: 'Error',
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.getPropinsi('Jawa Tengah');
    },

    methods: {
      getPropinsi (e = null) {
        // if (e.length < 3) return

        const requestBody = {
          search: e,
        }

        axios.post('/v1/general/get-province-entity', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.propinsi = res.data.data.province.list;
            this.propinsi.forEach(el => {
              this.selectedPropinsi = el;
            });
            this.getKabupatenKota();
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.setAlert(Object.values(e.response.data.error)[0][0])
            } else {
              this.setAlert(e.response.data.error)
            }
          })
      },

      getKabupatenKota () {
        const requestBody = {
          search: '',
          province_id: this.selectedPropinsi.province_id,
        }

        axios.post('/v1/general/get-city-entity', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.kabupatenKota = res.data.data.city.list
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.setAlert(Object.values(e.response.data.error)[0][0])
            } else {
              this.setAlert(e.response.data.error)
            }
          })
      },

      submit () {
        this.disableButton = true

        const requestBody = {
          province_id: this.selectedPropinsi.province_id,
          city_id: this.selectedKabupatenKota.city_id,
          reg_type: this.jenisKeanggotaan,
          reg_survey_1: this.sumberInfo,
          reg_survey_2: this.referensi,
        }

        axios.post('/v1/auth/register/step-1', requestBody).then((res) => {
          if (res.data.status === 200) {
            localStorage.setItem('step-1', res.data.data.reg_hash)
            localStorage.setItem('reg-type', this.jenisKeanggotaan)
            localStorage.setItem('tmp-1', JSON.stringify(requestBody))

            switch (this.jenisKeanggotaan) {
              case '1':
                this.$router.push({ name: 'RegisterProfesionalDataDiri' })
                break

              case '2':
                this.$router.push({ name: 'RegisterUniversitas' })
                break
            }
          }
        })
          .catch((e) => {
            this.disableButton = false

            if (typeof (e.response.data.error) === 'object') {
              this.setAlert(Object.values(e.response.data.error)[0][0])
            } else {
              this.setAlert(e.response.data.error)
            }
          })
      },

      setAlert (txt) {
        this.showAlert = true
        this.alertText = txt
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txt-login {
    @extend .h-4;
    color: $gray-50;
    font-weight: bold;
  }

  .txt-sub-1 {
    @extend .p-1;
    color: $gray-50;
  }

  .txt-sub-2 {
    @extend .p-1;
    color: $gray-50;
    font-style: italic;
  }

  .txt-sub-3 {
    @extend .p-2;
    color: $gray-50;
  }

  .txt-sub-4 {
    @extend .p-2;
    color: $blue-1;
    cursor: pointer;
    font-weight: bold;
  }

  .txt-sub-5 {
    @extend .p-2;
    color: $gray-80;
  }

  .txt-sub-6 {
    @extend .h-5;
    color: $black-1;
    font-weight: bold;
  }

  .txt-btn-login {
    @extend .h-5;
    color: #FFFFFF;
    font-weight: bold;
  }

  .btn-login {
    color: $green-2;
  }

  .symbol-required {
    color: $red-1;
  }

  .dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
  }

  .txt-12-green-2-bold {
    @extend .p-2;
    color: $green-2;
    font-weight: bold;
  }
</style>
